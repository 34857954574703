import {AUTH, LOGOUT, GET_ERROR_MESSAGE, CREATING_NEW_ONE} from '../constants/actionsTypes';

const authReducer = (state = {authData: null}, action) =>{
    switch (action.type){
        case AUTH: 
        
        localStorage.setItem('profile', JSON.stringify(action?.data));
        
        return { ... state,  ...action?.data};

        case CREATING_NEW_ONE: 

        return { ... state,  ...action?.data};

        case LOGOUT:
            localStorage.clear();
            return { ... state, authData: null, loading: false, errors: null };

        case GET_ERROR_MESSAGE:
            
            const returnedData = action.data;
            
            return {...state, authData: returnedData}

        default:
            return state;
    }
};

export default authReducer;