import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { getCurrentClientCases } from "../../actions/cases";
import { getCurrentClientInvoices } from "../../actions/invoices";
import { useSelector, useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Container, Grow, SvgIcon } from '@material-ui/core';

export default function InvoiceView() {

    let { id } = useParams();
    const history = useHistory();
    const [show, setShow] = useState(true);
    const [interval, setInterval] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const cases = useSelector((state) => state.cases);

    const dispatch = useDispatch();


    const logout = () => {
        dispatch({ type: 'LOGOUT' });

        setUser(null);
        history.push('/auth');
    }

    useEffect(() => {

        const token = user?.token;

        if (token) {
            const decodedToken = decode(token);

            if (decodedToken.exp * 1000 < new Date().getTime()) {
                logout();
            } else {
                dispatch(getCurrentClientCases());
                dispatch(getCurrentClientInvoices());
            }

        }


        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [dispatch]);

    const componentDidMount = () => {
        setInterval(
            setTimeout(function () {
                setShow(false);
            }, 5000)
        );
    }

    const componentWillUnmount = () => {
        setInterval(clearTimeout(interval));
    }

    const formatMoney = (amount) => {
		if(amount){
            return (amount).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            })
        }
	}

    const allInvoices = useSelector((state) => state.invoices);


    const currentInvoice = Object.values(allInvoices).filter((value, index) => {
        
        if (allInvoices[index].id == id && (allInvoices[index].status == 3 || allInvoices[index].status == 4)) {
            return true
        }
    });

    const currentInvoiceCase = Object.values(cases).filter((value, index) => {
        if (currentInvoice[0]?.caseID == cases[index]?.id) {
            return true;
        }
    });

    const SetStatus = (caseStatus) => {
        switch (caseStatus) {

            case 3:
                return "Factura Emitida"

            case 4:
                return "Factura Pagada"
            default: return "N/A"

        }
    }
    const formatDate = (date)=>{
		if(date){
			var myDate = new Date(date.slice(0, date.length-10));
		return myDate.toLocaleDateString("es-DO", {year: 'numeric', month: 'numeric', day: 'numeric'})
		}
	}

    return (
        <div>
            {
                currentInvoice ? <main>
                <div className="album py-5 bg-light">
                    <div className="container">
                        <div className="row mt-5 mb-4">
                            <div className="col">
                                <h4><i className="fas fa-file-invoice-dollar"></i> Factura: Concepto</h4>
                            </div>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Detalle de factura</li>
                            </ol>
                        </nav>
                        <dl className="row">
                            <dt className="col-sm-3">Numero de caso:</dt>
                            <dd className="col-sm-9">{currentInvoiceCase[0]?.id}</dd>
                            <dt className="col-sm-3">Nombre del caso:</dt>
                            <dd className="col-sm-9">{currentInvoiceCase[0]?.name}</dd>
                            <dt className="col-sm-3">Estado:</dt>
                            <dd className="col-sm-9"><span className={`badge bg-${currentInvoice[0]?.status == 4 ? "success clickableLabel" : "warning"}`}>{SetStatus(currentInvoice[0]?.status)}</span></dd>
                            <dt className="col-sm-3">No. de factura:</dt>
                            <dd className="col-sm-9">{currentInvoice[0]?.id}</dd>
                            <dt className="col-sm-3">RNC:</dt>
                            <dd className="col-sm-9">{currentInvoiceCase[0]?.client.govId}</dd>
                            <dt className="col-sm-3">Fecha de emisión:</dt>
                            <dd className="col-sm-9">{formatDate(currentInvoice[0]?.emitedDate)}</dd>
                            <dt className="col-sm-3">Monto total:</dt>
                            <dd className="col-sm-9">{formatMoney(currentInvoice[0]?.amountPlusTaxes)}</dd>
                            <dt className="col-sm-3">Moneda:</dt>
                            <dd className="col-sm-9">{currentInvoice[0]?.currency}</dd>
                        </dl>
                    </div>
                </div>
            </main> : show && <Container>
                <CircularProgress className="customCenter"/>
                </Container>
            }
        </div>
    )
}
