import {UPDATE_CLIENT} from '../constants/actionsTypes';



export default (requests = [], action)=>{
    switch (action.type) {
        case UPDATE_CLIENT:

            return action.response;
            default: 
            return requests;
    }
}