import React, { useState, useEffect } from 'react';

import Rating from '@material-ui/lab/Rating';
import RateReview from '@material-ui/icons/RateReview';
import ArrowBackSharp from '@material-ui/icons/ArrowBackSharp';
import './styles.css'
import { rateCloseCase } from '../../api';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRatingsByContact } from '../../actions/caseRatings';

import Box from '@material-ui/core/Box';
import { Send } from '@material-ui/icons';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { getCurrentClientCases } from '../../actions/cases';
import { TEXT_INPUT_VALIDATION } from '../../constants/regexValidations';

const initialFormValueForShortSurvey = {
    customerService: 0,
    quality: 0,
    assignedTeam: 0,
    deliverable: 0,
    recommendationChance: 0,
    comment: "",
    badCustomerServiceComment: "",
    badQualityComment: "",
    badAssignedTeamComment: "",
    badDeliverableComment: "",
    badRecommendationChanceComment: ""
};

const initialFormValueForLongSurvey = {
    usedServices: [],
    timeUsingService: "",
    satisfaction: "",
    attentionSpeed: "",
    rateInComparison: "",
    comment: "",
    recommendationChance: "",
    satisfactionRate: ""
};

const initialFormValueForExitSurvey = {
    reasonToGo: "",
    reasonToGetIn: "",
    recommendationChance: "",
    makeDifferent: "",
    workWithUsInTheFuture: ""
};

const RatingView = ({ currentCase }) => {

    

    const fieldsToText = {
        customerService: "Servicio al cliente",
        quality: "Calidad",
        assignedTeam: "Equipo asignado",
        deliverable: "Entregables",
        recommendationChance: "Posibilidad de recomendación",
        comment: "Opinión",
        badCustomerServiceComment: "Retroalimentación en servicio",
        badQualityComment: "Retroalimentación en calidad",
        badAssignedTeamComment: "Retroalimentación en equipo asignado",
        badDeliverableComment: "Retroalimentación en entregables",
        badRecommendationChanceComment: "Retroalimentación en equipo asignado",
        usedServices: "Servicios usados",
        timeUsingService: "Tiempo usando el servicio",
        satisfaction: "Satisfacción",
        attentionSpeed: "Atención al cliente",
        rateInComparison: "Calificación en comparación",
        recommendationChance: "Probabilidad de recomendación",
        satisfactionRate: "Satisfacción",
        reasonToGo: "Razón de salida",
        reasonToGetIn: "Razón de entrada",
        makeDifferent: "Que podemos hacer diferente",
        workWithUsInTheFuture: "Trabajaria con nosotros en el futuro"
    }

    const initialFormValues = () => {

        var result;

        switch (currentCase.caseSurveyInfo.surveyType) {
            case 1: result = initialFormValueForShortSurvey; break;
            case 2: result = initialFormValueForLongSurvey; break;
            case 3: result = initialFormValueForExitSurvey; break;
        }


        return result;
    }
    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem('profile')).result;

    useEffect(() => {
        dispatch(getAllRatingsByContact(user.id));
        dispatch(getCurrentClientCases())
    }, [])

    const ratings = useSelector(state => {
        return state.ratingsOfUser
    })

    const [show, setShow] = useState(false);

    const [formData, setFormData] = useState(initialFormValues());

    const [errorMessages, setErrorMessages] = useState([]);

    const handleChange = (e) => {

        e.target.value = e.target.value.replace(TEXT_INPUT_VALIDATION, '');

        setFormData({ ...formData, [e.target.name]: e.target.value });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        switch (currentCase.caseSurveyInfo.surveyType) {
            case 1: {
                
                let emptyFields = Object.keys(formData).filter((item, index) => {
                    if (formData[item] === 0) {

                        return item
                    }
                })

                if (emptyFields.length > 0) {
                    setErrorMessages(emptyFields);
                } else {
                    formData.customerService = parseFloat(formData.customerService);
                    formData.quality = parseFloat(formData.quality);
                    formData.assignedTeam = parseFloat(formData.assignedTeam);
                    formData.deliverable = parseFloat(formData.deliverable)
                    formData.recommendationChance = (parseInt(formData.recommendationChance) / 2);
    
                    rateCloseCase(user.id, currentCase.id, currentCase.caseSurveyInfo.surveyType, formData);
                    setFormData(initialFormValues);
                    setShow(true)

                }

                break;


            }
            case 2: {

                let emptyFields = Object.keys(formData).filter((item, index) => {
                    if (formData[item] == "" || formData[item].length == 0) {
                        return item
                    }
                })

                if (emptyFields.length > 0) {
                    setErrorMessages(emptyFields);
                } else {

                    rateCloseCase(user.id, currentCase.id, 2, formData);
                    setFormData(initialFormValues);
                    setShow(true)
                }
                break;
            }
            case 3: {
                let emptyFields = Object.keys(formData).filter((item, index) => {
                    if (formData[item] == "" || formData[item].length == 0) {
                        return item
                    }
                })

                if (emptyFields.length > 0) {
                    setErrorMessages(emptyFields);
                } else {
                    formData.workWithUsInTheFuture == "Si" ? formData.workWithUsInTheFuture = true : formData.workWithUsInTheFuture = false;
             
                    rateCloseCase(user.id, currentCase.id, 3, formData);
                    setFormData(initialFormValues);
                    setShow(true)
                }
                break;
            }
        }


    }



    const currentCaseAndContactRate = Object.values(ratings).filter((item, index) => {
      
        if (ratings[index].case == currentCase.id) {
            return ratings[index].surveyData;
        }
    });


    const isThereRatingOfCase = !currentCase.caseSurveyInfo.sendSurvey;


    const [serviceHover, setServiceHover] = useState(-1);
    const [qualityHover, setQualityHover] = useState(-1);
    const [teamHover, setTeamHover] = useState(-1);
    const [deliverableHover, setDeliverableHover] = useState(-1);
    const [recommendHover, setRecommendHover] = useState(-1);

    const labels = {
        0.5: 'Lo peor',
        1: 'Terrible',
        1.5: 'Muy malo',
        2: 'Malo',
        2.5: 'Regular',
        3: 'Poco más que regular',
        3.5: 'Bueno',
        4: 'Muy bueno',
        4.5: 'Excelente',
        5: 'Perfecto',
    };

    const customLabels = {
        1: 'Nula :(',
        2: 'Casi nula',
        3: 'Muy poca',
        4: 'Poca',
        5: 'Puede ser',
        6: 'Es probable que lo haga',
        7: 'Lo haría',
        8: 'Seguro',
        9: 'Por supuesto que lo haría',
        10: 'Sin duda alguna :)',
    };

    const clearCustomerService = () => {
        if (currentCase.caseSurveyInfo.surveyType == 1) {
            formData.badCustomerServiceComment = "";
        }
    }

    const clearQuality = () => {
        if (currentCase.caseSurveyInfo.surveyType == 1) {
            formData.badQualityComment = "";
        }
    }

    const clearAssignedTeam = () => {
        if (currentCase.caseSurveyInfo.surveyType == 1) {
            formData.badAssignedTeamComment = "";
        }

    }

    const clearDeliverable = () => {
        if (currentCase.caseSurveyInfo.surveyType == 1) {
            formData.badDeliverableComment = "";
        }

    }

    const clearRecommendation = () => {
        if (currentCase.caseSurveyInfo.surveyType == 1) {
            formData.badRecommendationChanceComment = "";
        }

    }

    const [servicesList, setServicesList] = useState({
        Auditoria: false,
        Outsourcing: false,
        Impuestos: false,
        Consultoria: false,
        Otros: false
    })

    const onServiceChoose = (e) => {

        let valueSelected = e.target.value;


        setServicesList({ ...servicesList, [e.target.name]: e.target.checked });

        if (formData.usedServices.includes(valueSelected)) {
            formData.usedServices.splice(formData.usedServices.indexOf(valueSelected), 1)

        } else {
            formData.usedServices.push(valueSelected);
        }

    }




    return <>



        {
            
            isThereRatingOfCase ?
                <div className="container">

                    <div className="row mt-5">
                        <div className="col-sm-9 ml-auto mr-5">
                            <h3 className="col"> <RateReview /> {`¡Gracias por tu retroalimentación en el caso ${currentCase?.name}, nos ayudas a ser mejor!`}</h3>
                        </div>

                        <h5>
                            <a href={`/cases/${currentCase.id}`}>
                            <label  className="mr-4 customClickableLabel">
                                <ArrowBackSharp size="large" />
                                {"  Volver al caso"}
                            </label>
                            </a>
                        </h5>
                    </div>
                </div>

                :

                {



                    "1": <form onSubmit={handleSubmit}>

                        <div className="container">
                            <div className="row mt-5">
                                <div className="col">
                                    <h3> <RateReview /> {`¿Cómo evaluaría su satisfacción respecto al servicio entregado por parte de Baker Tilly en el caso ${currentCase?.name}?`}</h3>
                                </div>

                                <a href={`/cases/${currentCase.id}`} className="customChoice">
                            <label  className="mr-4 customClickableLabel">
                                <ArrowBackSharp size="large" />
                                {"  Volver al caso"}
                            </label>
                            </a>
                            </div>


                            <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2">

                                    <strong><h5>1. Servicio al cliente</h5></strong>
                                    {errorMessages.includes("customerService") && <p className="customText">Este campo es requerido *</p>}
                                </dt>

                                <dd className="col-sm-9"><Box component="fieldset" borderColor="transparent">

                                    <Rating
                                        name="customerService"
                                        required={true}
                                        precision={0.5} size="large"
                                        onChange={handleChange}
                                        onChangeActive={(event, newHover) => {
                                            setServiceHover(newHover);
                                        }}
                                        readOnly={show || isThereRatingOfCase}
                                        defaultValue={isThereRatingOfCase && currentCaseAndContactRate[0]?.customerService / 2} />
                                    {formData?.customerService !== null && <Box ml={2}>{labels[serviceHover !== -1 ? serviceHover : formData?.customerService]}</Box>}
                                </Box></dd>
                            </dl>

                            {(formData?.customerService <= 3 && formData?.customerService > 0) ? <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>¿Por que nos das esta calificación en servicio al cliente?</h5></strong></dt>
                                <dd className="col-sm-9"><textarea required={true} className="form-control" name="badCustomerServiceComment" cols="100" rows="3" placeholder=" Escribe tus inquietudes respecto al servicio aquí..." onChange={handleChange}></textarea></dd>
                            </dl> : clearCustomerService()}



                            <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>2. Calidad</h5></strong>
                                    {errorMessages.includes("quality") && <p className="customText">Este campo es requerido *</p>}</dt>
                                <dd className="col-sm-9"><Box component="fieldset" borderColor="transparent">
                                    <Rating
                                        name="quality"
                                        precision={0.5} size="large"
                                        onChange={handleChange}
                                        readOnly={show || isThereRatingOfCase}
                                        onChangeActive={(event, newHover) => {
                                            setQualityHover(newHover);
                                        }}
                                        defaultValue={isThereRatingOfCase && currentCaseAndContactRate[0].quality / 2} />
                                    {formData.quality !== null && <Box ml={2}>{labels[qualityHover !== -1 ? qualityHover : formData.quality]}</Box>}
                                </Box></dd>
                            </dl>

                            {(formData.quality <= 3 && formData.quality > 0) ? <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>¿Por que nos das esta calificación en la calidad?</h5></strong></dt>
                                <dd className="col-sm-9"><textarea required={true} className="form-control" name="badQualityComment" cols="100" rows="3" placeholder=" Escribe tus inquietudes respecto a la calidad aquí..." onChange={handleChange}></textarea></dd>
                            </dl> : clearQuality()}



                            <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>3. Equipo asignado</h5></strong>
                                    {errorMessages.includes("assignedTeam") && <p className="customText">Este campo es requerido *</p>}</dt>
                                <dd className="col-sm-9"><Box component="fieldset" borderColor="transparent">
                                    <Rating
                                        name="assignedTeam"
                                        precision={0.5} size="large"
                                        onChange={handleChange}
                                        readOnly={show || isThereRatingOfCase}
                                        onChangeActive={(event, newHover) => {
                                            setTeamHover(newHover);
                                        }}
                                        defaultValue={isThereRatingOfCase && currentCaseAndContactRate[0].assignedTeam / 2} />
                                    {formData.assignedTeam !== null && <Box ml={2}>{labels[teamHover !== -1 ? teamHover : formData.assignedTeam]}</Box>}
                                </Box></dd>
                            </dl>

                            {(formData.assignedTeam <= 3 && formData.assignedTeam > 0) ? <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2">
                                    <strong>
                                        <h5>¿Por que nos das esta calificación en la equipo asignado?</h5>
                                    </strong>
                                </dt>
                                <dd className="col-sm-9"><textarea required={true} className="form-control" name="badAssignedTeamComment" cols="100" rows="3" placeholder=" Escribe tus inquietudes respecto al equipo aquí..." onChange={handleChange}></textarea></dd>
                            </dl> : clearAssignedTeam()}



                            <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>4. Entregable</h5></strong>
                                    {errorMessages.includes("deliverable") && <p className="customText">Este campo es requerido *</p>}</dt>
                                <dd className="col-sm-9"><Box component="fieldset" borderColor="transparent">
                                    <Rating
                                        name="deliverable"
                                        precision={0.5} size="large"
                                        onChange={handleChange}
                                        readOnly={show || isThereRatingOfCase}
                                        onChangeActive={(event, newHover) => {
                                            setDeliverableHover(newHover);
                                        }}
                                        defaultValue={isThereRatingOfCase && currentCaseAndContactRate[0].deliverable / 2} />
                                    {formData.deliverable !== null && <Box ml={2}>{labels[deliverableHover !== -1 ? deliverableHover : formData.deliverable]}</Box>}
                                </Box></dd>
                            </dl>

                            {(formData.deliverable <= 3 && formData.deliverable > 0) ? <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>¿Por que nos das esta calificación a los entregables?</h5></strong></dt>

                                <dd className="col-sm-9"><textarea required={true} className="form-control" name="badDeliverableComment" cols="100" rows="3" placeholder=" Escribe tus inquietudes respecto a los entregables aquí..." onChange={handleChange}></textarea></dd>
                            </dl> : clearDeliverable()}




                            <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>5. ¿Cuál es la probabilidad de que recomiende la empresa a algún amigo o conocido?</h5></strong>

                                    {errorMessages.includes("recommendationChance") && <p className="customText">Este campo es requerido *</p>}

                                </dt>

                                <dd className="col-sm-9">
                                    <Box component="fieldset" mb={3} borderColor="transparent">
                                        <Rating name="recommendationChance"
                                            size="large"
                                            defaultValue={isThereRatingOfCase && currentCaseAndContactRate[0].recomendationChance}
                                            onChange={handleChange}
                                            max={10}
                                            readOnly={show || isThereRatingOfCase}
                                            onChangeActive={(event, newHover) => {
                                                setRecommendHover(newHover);
                                            }} />
                                        {formData.recommendationChance !== null && <Box ml={2}>{customLabels[recommendHover !== -1 ? recommendHover : formData.recommendationChance]}</Box>}
                                    </Box></dd>
                            </dl>

                            {(formData.recommendationChance <= 6 && formData.recommendationChance > 0) ? <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>¿Por que no es tan probable que nos recomiendes?</h5></strong></dt>
                                <dd className="col-sm-9"><textarea required={true} className="form-control" name="badRecommendationChanceComment" cols="100" rows="3" placeholder=" Amplia un poco más aquí..." onChange={handleChange}></textarea></dd>
                            </dl> : clearRecommendation()}




                            <dl className="row mt-5">
                                <dt className="col-sm-3 mt-2"><strong><h5>Déjanos tus opiniones de como mejorar aquí!</h5></strong></dt>
                                <dd className="col-sm-9"><textarea
                                    className="form-control"
                                    name="comment"
                                    required={true}
                                    type="text-area"
                                    rows="5"
                                    cols="100"
                                    onChange={handleChange}
                                    readOnly={show || isThereRatingOfCase}
                                    defaultValue={isThereRatingOfCase ? currentCaseAndContactRate[0].comment : ""}></textarea ></dd>
                            </dl>

                            <div>
                            </div>

                            <dl className="row mt-5">
                                <dt className="col-sm-4 mt-2"></dt>
                                <dd className="col-sm-4">{show || isThereRatingOfCase ?
                                    <Alert variant="info" show={show || isThereRatingOfCase}><span>Su feedback ha sido enviado, gracias!</span></Alert> :
                                    
                                    <>
                                    {errorMessages.length > 0 && <p className="customText">Hay campos requeridos por llenar.*</p>}
                                    <button type="submit" className="form-control btn-lg btn-info"> <h5> <Send /> Enviar</h5> </button>
                                    </>}
                                    
                                    </dd>
                                <dd className="col-sm-4"></dd>
                            </dl>
                        </div>
                    </form>,

                    "2": <form onSubmit={handleSubmit}>

                        <div className="container">
                            <div className="row mt-5">
                                <div className="col">
                                    <h3> <RateReview /> {`¡Nos interesa tu opnión! Por favor responde con sinceridad las siguientes preguntas acerca del caso ${currentCase?.name}`}</h3>
                                </div>

                                <h5>
                                <a href={`/cases/${currentCase.id}`} className="customChoice">
                            <label  className="mr-4 customClickableLabel">
                                <ArrowBackSharp size="large" />
                                {"  Volver al caso"}
                            </label>
                            </a>
                                </h5>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">1. ¿Que servicios de la firma utiliza actualmente?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("usedServices") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl>
                                    <FormControlLabel name="Auditoria" value="Auditoria" checked={servicesList.Auditoria} control={<Checkbox />} label="Auditoria" onChange={onServiceChoose} />
                                    <FormControlLabel name="Outsourcing" value="Outsourcing" checked={servicesList.Outsourcing} control={<Checkbox />} label="Outsourcing" onChange={onServiceChoose} />
                                    <FormControlLabel name="Impuestos" value="Impuestos" checked={servicesList.Impuestos} control={<Checkbox />} label="Impuestos" onChange={onServiceChoose} />
                                    <FormControlLabel name="Consultoria" value="Consultoria" checked={servicesList.Consultoria} control={<Checkbox />} label="Consultoria" onChange={onServiceChoose} />
                                    <FormControlLabel name="Otros" value="Otros" checked={servicesList.Otros} control={<Checkbox />} label="Otros" onChange={onServiceChoose} />
                                </FormControl>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">2. ¿Desde hace cuánto utiliza nuestros servicios?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("timeUsingService") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl required={true}>


                                    <RadioGroup aria-label="gender" name="timeUsingService" value={formData.timeUsingService} onChange={handleChange} >
                                        <FormControlLabel value="Menos de un año" control={<Radio />} label="Menos de un año" />
                                        <FormControlLabel value="1 a 3 años" control={<Radio />} label="1 a 3 años" />
                                        <FormControlLabel value="3 a 5 años" control={<Radio />} label="3 a 5 años" />
                                        <FormControlLabel value="5 a 10 años" control={<Radio />} label="5 a 10 años" />
                                        <FormControlLabel value="Mayores a 10 años" control={<Radio />} label="Mayores a 10 años" />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">3. ¿Considera que el servicio satisface sus necesidades?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("satisfaction") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl component="fieldset" required={true}>


                                    <RadioGroup aria-label="gender" name="satisfaction" value={formData.satisfaction} onChange={handleChange}>
                                        <FormControlLabel value="Supera mis expectativas" control={<Radio />} label="Supera mis expectativas" />
                                        <FormControlLabel value="Cumple con mis expectativas" control={<Radio />} label="Cumple con mis expectativas" />
                                        <FormControlLabel value="Necesita mejorar" control={<Radio />} label="Necesita mejorar" />
                                    </RadioGroup>
                                </FormControl>
                            </div>


                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">4. ¿Cómo considera que es nuestra atención para resolver sus dudas e inquietudes?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("attentionSpeed") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl component="fieldset" required={true}>


                                    <RadioGroup aria-label="gender" name="attentionSpeed" value={formData.attentionSpeed} onChange={handleChange}>
                                        <FormControlLabel value="Rápida" control={<Radio />} label="Rápida" />
                                        <FormControlLabel value="Normal" control={<Radio />} label="Normal" />
                                        <FormControlLabel value="Lenta" control={<Radio />} label="Lenta" />
                                        <FormControlLabel value="Insuficiente" control={<Radio />} label="Insuficiente" />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">5. Si piensa en servicios parecidos, ¿cuál es su calificación para nuestra firma?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("rateInComparison") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl component="fieldset" required={true}>


                                    <RadioGroup aria-label="gender" name="rateInComparison" value={formData.rateInComparison} onChange={handleChange}>
                                        <FormControlLabel value="Es la mejor opción para mis necesidades." control={<Radio />} label="Es la mejor opción para mis necesidades." />
                                        <FormControlLabel value="Es la mejor opción hasta ahora, pero buscaré alternativas." control={<Radio />} label="Es la mejor opción hasta ahora, pero buscaré alternativas." />
                                        <FormControlLabel value="Es una buena opción, pero creo que hay mejores." control={<Radio />} label="Es una buena opción, pero creo que hay mejores." />
                                        <FormControlLabel value="No es una buena opción." control={<Radio />} label="No es una buena opción." />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">6. ¿Cuál es la probabilidad de que nos recomiende con sus conocidos?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("recommendationChance") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl component="fieldset" required={true}>


                                    <RadioGroup aria-label="gender" name="recommendationChance" value={formData.recommendationChance} onChange={handleChange}>
                                        <FormControlLabel value="Siempre los recomiendo" control={<Radio />} label="Siempre los recomiendo" />
                                        <FormControlLabel value="Muy probable" control={<Radio />} label="Muy probable" />
                                        <FormControlLabel value="Probable" control={<Radio />} label="Probable" />
                                        <FormControlLabel value="Poco probable" control={<Radio />} label="Poco probable" />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">7. En general, ¿qué tan satisfecho está con nuestra firma?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("satisfactionRate") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl component="fieldset" required={true}>


                                    <RadioGroup aria-label="gender" name="satisfactionRate" value={formData.satisfactionRate} onChange={handleChange}>
                                        <FormControlLabel value="Muy satisfecho" control={<Radio />} label="Muy satisfecho" />
                                        <FormControlLabel value="Satisfecho" control={<Radio />} label="Satisfecho" />
                                        <FormControlLabel value="Poco satisfecho" control={<Radio />} label="Poco satisfecho" />
                                        <FormControlLabel value="Insatisfecho" control={<Radio />} label="Insatisfecho" />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><h5>8. ¿Cuál es el aspecto o aspectos que mejoraría en su experiencia con nuestra firma?</h5></strong></div>
                            <div className="col-sm-12 mt-5">
                                <textarea
                                    className="form-control"
                                    name="comment"
                                    value={formData.comment}
                                    required={true}
                                    type="text-area"
                                    rows="5"
                                    cols="100"
                                    onChange={handleChange}></textarea >
                            </div>

                            <div>
                            </div>

                            <dl className="row mt-5">
                                <dt className="col-sm-4 mt-2"></dt>



                                {show || isThereRatingOfCase ?
                                    <Alert variant="info" show={show || isThereRatingOfCase}><span>Su feedback ha sido enviado, gracias!</span></Alert> :
                                    <>
                                        {
                                            errorMessages.length > 0 && <dd className="col-sm-7">

                                                <p className="customText">Hay campos requeridos por llenar. *</p>

                                            </dd>

                                        }

                                        <dd className="col-sm-4 ml-auto">
                                            <button type="submit" className="form-control btn-lg btn-info"> <h5> <Send /> Enviar</h5> </button>
                                        </dd>
                                    </>}
                                <dd className="col-sm-4"></dd>
                            </dl>

                        </div>
                    </form>,

                    "3": <form onSubmit={handleSubmit}>

                        <div className="container">
                            <div className="row mt-5">
                                <div className="col">
                                    <h3> <RateReview /> {`Breve encuesta de satisfacción de clientes en el caso ${currentCase?.name}`}</h3>
                                    <p className="mt-5"> <strong>Estimado cliente:</strong> <br /> En Baker Tilly valoramos mucho su opinión y es por esto que
                    solicitamos su cooperación para completar esta breve encuesta por motivo de su salida, con la espera de
                    continuar mejorando nuestros servicios.  </p>
                                </div>

                                <h5>
                                <a href={`/cases/${currentCase.id}`} className="customChoice">
                            <label  className="mr-4 customClickableLabel">
                                <ArrowBackSharp size="large" />
                                {"  Volver al caso"}
                            </label>
                            </a>
                                </h5>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><h5>1. ¿Cuál fue el motivo principal por el que has decidido dejar de hacer negocios con Baker Tilly?</h5></strong></div>
                            <div className="col-sm-12 mt-5">
                                {errorMessages.includes("reasonToGo") && <p className="customText">Este campo es requerido *</p>}
                                <textarea
                                    className="form-control"
                                    name="reasonToGo"
                                    value={formData.reasonToGo}
                                    required={true}
                                    type="text-area"
                                    rows="5"
                                    cols="100"
                                    onChange={handleChange}></textarea >
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">2. ¿Cuál es la razón por la que te convertiste inicialmente en nuestro cliente?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("reasonToGetIn") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl required={true}>


                                    <RadioGroup aria-label="gender" name="reasonToGetIn" value={formData.reasonToGetIn} onChange={handleChange} >
                                        <FormControlLabel value="Valor de la marca" control={<Radio />} label="Valor de la marca" />
                                        <FormControlLabel value="Marketing y/o publicidad" control={<Radio />} label="Marketing y/o publicidad" />
                                        <FormControlLabel value="Por recomendación" control={<Radio />} label="Por recomendación" />
                                        <FormControlLabel value="Precio" control={<Radio />} label="Precio" />
                                        <FormControlLabel value="Otro" control={<Radio />} label="Otro" />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">3. ¿Cuál es la probabilidad de que nos recomiende con sus conocidos?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("recommendationChance") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl component="fieldset" required={true}>


                                    <RadioGroup aria-label="gender" name="recommendationChance" value={formData.recommendationChance} onChange={handleChange}>
                                        <FormControlLabel value="Muy probable" control={<Radio />} label="Muy probable" />
                                        <FormControlLabel value="Probable" control={<Radio />} label="Probable" />
                                        <FormControlLabel value="Ninguna" control={<Radio />} label="Ninguna" />
                                    </RadioGroup>
                                </FormControl>
                            </div>


                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">4. ¿Hay algo que podríamos haber hecho diferente?</h5></FormLabel></strong></div>
                            <div className="col-sm-12 mt-5">
                                {errorMessages.includes("makeDifferent") && <p className="customText">Este campo es requerido *</p>}
                                <textarea
                                    className="form-control"
                                    name="makeDifferent"
                                    value={formData.makeDifferent}
                                    required={true}
                                    type="text-area"
                                    rows="5"
                                    cols="100"
                                    onChange={handleChange}></textarea >
                            </div>

                            <div className="col-sm-10 mt-5"><strong><FormLabel ><h5 className="customChoice">5. ¿Considerarías volver a trabajar con nosotros en un futuro si solucionáramos esos aspectos que mencionas?</h5></FormLabel></strong></div>
                            <div className="col-sm-9 mt-2">
                                {errorMessages.includes("workWithUsInTheFuture") && <p className="customText">Este campo es requerido *</p>}
                                <FormControl component="fieldset" required={true}>


                                    <RadioGroup aria-label="gender" name="workWithUsInTheFuture" value={formData.workWithUsInTheFuture} onChange={handleChange}>
                                        <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </div>



                            <div>
                            </div>

                            <dl className="row mt-5">
                                <dt className="col-sm-4 mt-2"></dt>



                                {show || isThereRatingOfCase ?
                                    <Alert variant="info" show={show || isThereRatingOfCase}><span>Su feedback ha sido enviado, gracias!</span></Alert> :
                                    <>
                                        {
                                            errorMessages.length > 0 && <dd className="col-sm-7">


                                                {
                                                    errorMessages.map((item) => {
                                                        return <p className="customText">{`El campo ${fieldsToText[item]} es requerido. *`}</p>
                                                    })
                                                }
                                            </dd>

                                        }

                                        <dd className="col-sm-4 ml-auto">
                                            <button type="submit" className="form-control btn-lg btn-info"> <h5> <Send /> Enviar</h5> </button>
                                        </dd>
                                    </>}
                                <dd className="col-sm-4"></dd>
                            </dl>

                        </div>
                    </form>
                }[currentCase.caseSurveyInfo.surveyType.toString()]}

    </>
}

export default RatingView;