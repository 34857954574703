import React from 'react';
import { TextField, Grid, InputAdornment, IconButton } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import './styles.css';

const Input = ({ name, handleChange, label, half, autoFocus, type, handleShowPassword }) => (
    <Grid item className="marginDown" xs={12} sm={half ? 6 : 12}>
        <TextField
            className={(type == 'password' || type=='email' || type=='text') && "textField"}
            name={name}
            onChange={handleChange}
            variant="outlined"
            required
            fullWidth
            label={label}
            autoFocus={autoFocus}
            type={type}
            InputProps={
                name == 'password' ? {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword}>
                                {type === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    )
                } : null
            }
        />

    </Grid>
)

export default Input;