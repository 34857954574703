import {GET_ALL_REQUESTS} from '../constants/actionsTypes';



export default (requests = [], action)=>{
    switch (action.type) {
        case GET_ALL_REQUESTS:

             return action.response;
            default: 
            return requests;
    }
}