import * as api from '../api';
import {UPDATE_CLIENT} from '../constants/actionsTypes';

export const updateClient = (leadId, rnc) => async (dispatch)=>{
    api.updateGovId(leadId, rnc).then((response)=>{


        dispatch({type: UPDATE_CLIENT, response: response.data})


    })
}