import React, {useState} from 'react'
import { useSelector } from 'react-redux';

const CommentComponent = ({caseComment})=> {

	//const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).result);

	const client = useSelector(state => state.client);

	


	const formatName = (userName)=>{
		let userNameArr = userName.split('.');
		return userNameArr[0]?.charAt(0).toUpperCase() + userNameArr[0]?.slice(1) + " " 
		+ userNameArr[1]?.charAt(0).toUpperCase() + userNameArr[1]?.slice(1, userNameArr[1]?.indexOf("@")) + " de BakerTilly";
	}

	const formatDate = (stringDate)=>{
		let date = stringDate?.slice(0,stringDate.length-1)

		var formatDate = new Date(date);


		return formatDate.toUTCString().slice(0,16);
	}


    return (
        <div className="link-text">
									<div className="list-icon">
										<i className="fas fa-comment"></i>
									</div>
									<div className="container">

									<span className="row">
											<strong>{caseComment.title}</strong>
										</span>

										<small className="row md-5">{formatDate(caseComment.commentedOn) + (caseComment.isIntern ? 	`, ${formatName(caseComment.commentedBy)}` : `, ${caseComment.commentedBy} de ${client.companyName}`)}</small>
										
										

										<span className="row mt-2">
											<span >{caseComment.comment}</span><br />
											
										</span>

										<span className="row ">--------</span>

										<br />

									</div>
								</div>
    )
}

export default CommentComponent;
