import {GET_USER_CLIENT } from '../constants/actionsTypes'
import * as api from '../api';

// Action creators
export const getCurrentClient = ()=> async (dispatch) => {
    try {
        await api.getCurrentClient()
        .then((response)=>{
        dispatch({type: GET_USER_CLIENT, data: response[0]});

        })
    } catch (error) {
        console.log(error.message);
    }

}