import {GET_USER_CLIENT_CASES} from "../constants/actionsTypes";
import * as api from "../api/index";

export const getCurrentClientCases = ()=> async (dispatch) => {
    
    try {
        await api.getCurrentClientCases()
        .then((data)=>{
        
        dispatch({type: GET_USER_CLIENT_CASES, data});

        })

        
    } catch (error) {
        console.log(error.message);

    }

}