import React, { useState } from "react";
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import "./ContactCRUD.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Alert } from 'react-bootstrap';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter
} from "reactstrap";
import {signup} from '../../../actions/auth'
import CheckBox from "@material-ui/icons/CheckBox";
import {removeContactService} from '../../../api';
import { Add, Cancel, Delete, Edit, Save } from "@material-ui/icons";
import { EMAIL_VALIDATION, TEXT_INPUT_VALIDATION } from "../../../constants/regexValidations";


const ContactCRUD = ({dataToGrid})=> {

  const dispatch = useDispatch();
  const history = useHistory();

    const [data, setData] = useState(dataToGrid);
    const [updateModal, setUpdateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [pswConfirm, setPswConfirm] = useState("");
    const user = JSON.parse(localStorage.getItem('profile')).result;
    const [message, setMessage] = useState("");

    const initialFormValue = {
      id: "",
      email: "",
      ext: "",
      isActive: 1,
      reset: 1,
      name: "",
      password: "",
      phoneNumber: "",
      position: "",
      educationLevel: "",
      department: "",
      leadId: user.leadId
      };

    const [form, setForm] = useState(initialFormValue);
  

  const showUpdateModal = (currentContact) => {
      setForm(currentContact);
      setUpdateModal(true)
    
  };

  const closeUpdateModal = () => {
      setUpdateModal(false);
      setForm(initialFormValue);
  };

  const showAddModal = () => {
      setAddModal(true);
      
  };

  const closeAddModal = () => {
      setAddModal(false);
      setPswConfirm("");
      setForm(initialFormValue);
  };


  const updateContact = (currentContact) => {
    var _data = data;
    _data.map((item, index) => {
      if (currentContact.id == item.id) {
        _data[index].name = currentContact.name;
        _data[index].phoneNumber = currentContact.phoneNumber;
        _data[index].ext = currentContact.ext;
        _data[index].email = currentContact.email;
        _data[index].isActive = currentContact.isActive;
        _data[index].reset = currentContact.reset;
        _data[index].password = currentContact.password;
        _data[index].position = currentContact.position;
        _data[index].educationLevel = currentContact.educationLevel;
        _data[index].department = currentContact.department;
        _data[index].leadId = currentContact.leadId;

        var contactToUpdate = _data[index]

        dispatch(signup(contactToUpdate, history, false, false, true));
      }
    });

      setData(_data);
      setForm(initialFormValue);
      setUpdateModal(false);
    
  };
  const showRemoveModal = (currentContact)=>{
        setRemoveModal(true);
        setForm(currentContact);
  }

  const removeContact = (currentContact) => {

    removeContactService(currentContact.id);

      var _data = data;
      _data.map((item, index) => {
        if (currentContact.id == item.id) {
          _data.splice(index, 1);
        }
      });

      
      
      setData(_data);
      setRemoveModal(false);
  };

  const addNewContact = (e)=>{
    e.preventDefault();
    let userExist = false;

    var newContact= {...form};

    if(form.password == pswConfirm && EMAIL_VALIDATION.test(form.email) && form.password.length >= 8){
      
      
      newContact.id=data[data.length-1].id+1;
      var _data = data;
      

      data.map((item, index) =>{
        if(newContact.email == item.email){
          userExist = true;
        }
      })

      if(userExist){
        
        setMessage("Este email ya está registrado, por favor intente otro.");
      }else{
      dispatch(signup(newContact, history, false, true, false));
      _data.push(newContact);
      setAddModal(false);
      setPswConfirm("");
      setForm(initialFormValue);
      setData(_data);
      }
    }


    
  }

  const handleChange = (e) => {
      if(!(e.target.name == "password") && !(e.target.name == "email")){
        e.target.value = e.target.value.replace(TEXT_INPUT_VALIDATION, '');
      }
      setForm({
        ...form,
        [e.target.name]: e.target.value
      });
  };
  
  const handlePasswordConfirmChange = (e)=>{
        setPswConfirm(e.target.value);
  }
  
    
    return (
      <>
        <Container className="customGrid">
        <Button color="success" onClick={()=>showAddModal()} className="mb-4"> <Add/> Nuevo usuario</Button>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Telefono</th>
                <th>Ext.</th>
                <th>Email</th>
                <th>Pocisión</th>
                <th>Opciones</th>
              </tr>
            </thead>

            <tbody>
              {data.map((currentContact) => (
                <tr key={currentContact.id}>
                  <td>{currentContact.id}</td>
                  <td>{currentContact.name}</td>
                  <td>{currentContact.phoneNumber}</td>
                  <td>{currentContact.ext}</td>
                  <td>{currentContact.email}</td>
                  <td>{currentContact.position}</td>
                  <td>
                    <Button
                      color="info"
                      onClick={() => showUpdateModal(currentContact)}
                    >
                      <Edit/>
                      {"  Editar"}
                    </Button>{" "}
                    <Button color="danger" onClick={()=> showRemoveModal(currentContact)}>
                      <Delete/>
                      {"  Eliminar"}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>


        <Modal isOpen={updateModal}>
          <ModalHeader>
           <div><h3>Editar contacto</h3></div>
          </ModalHeader>

          <ModalBody>
            <FormGroup>
              <label>
               Id:
              </label>
            
              <input
                className="form-control"
                readOnly
                type="text"
                
                defaultValue={form.id}
              />
            </FormGroup>
            
            <FormGroup>
              <label>
                Nombre: 
              </label>
              <input
                className="form-control"
                name="name"
                type="text"
                onChange={handleChange}
                defaultValue={form.name}
              />
            </FormGroup>
            
            <FormGroup>
              <label>
                Telefono: 
              </label>
              <input
                className="form-control"
                name="phoneNumber"
                type="text"
                onChange={handleChange}
                value={form.phoneNumber}
              />
            </FormGroup>

            <FormGroup>
              <label>
                Ext.: 
              </label>
              <input
                className="form-control"
                name="ext"
                type="text"
                onChange={handleChange}
                value={form.ext}
              />
            </FormGroup>

            <FormGroup>
              <label>
                Email: 
              </label>
              <input
                className="form-control"
                name="email"
                type="text"
                onChange={handleChange}
                value={form.email}
                readOnly={true}
              />
            </FormGroup>

            <FormGroup>
              <label>
                Posición: 
              </label>
              <input
                className="form-control"
                name="position"
                type="text"
                onChange={handleChange}
                value={form.position}
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            
            
            <Button
            className="mr-auto"
              color="danger"
              onClick={() => closeUpdateModal()}
            >
              <Cancel/>
              {"  Cancelar"}
            </Button>

            <Button
            
              color="info"
              onClick={() => updateContact(form)}
            >
              <Save/>
              {"  Guardar"}
            </Button>

            
          </ModalFooter>
        </Modal>



        <Modal isOpen={addModal}>
          <form onSubmit={addNewContact}>
            
          <ModalHeader>
           <div><h3>Insertar contacto</h3></div>
          </ModalHeader>

          <ModalBody>
            
            <FormGroup>
              <label>
                Nombre: 
              </label>
              <input
                className="form-control"
                name="name"
                type="text"
                onChange={handleChange}
                required={true}
              />
            </FormGroup>
            
            <FormGroup>
              <label>
                Telefono: 
              </label>
              <input
                className="form-control"
                name="phoneNumber"
                type="text"
                required={true}
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <label>
                Ext.: 
              </label>
              <input
                className="form-control"
                name="ext"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>

            {
              message && <Alert variant="danger" onClose={()=> setMessage("")} dismissible>
              {message}
          </Alert>
            }

            <FormGroup>
              <label>
                Email: 
              </label>
              <input
                className="form-control"
                name="email"
                type="text"
                required={true}
                onChange={handleChange}
              />
              {
                form.email && (!EMAIL_VALIDATION.test(form.email) ? <p className="customDangerLabel">Por favor introduzca un email válido.</p> : <CheckBox className="customSuccessLabel"/>)
              }
            </FormGroup>

            <FormGroup>
              <label>
                Contraseña: 
              </label>
              <input
                className="form-control"
                name="password"
                type="password"
                required={true}
                onChange={handleChange}
              />

              {
                form.password && (form.password.length < 8 && <p className="customDangerLabel">La contraseña debe tener más de 8 caracteres.</p>)
              }
            </FormGroup>

            <FormGroup>
              <label>
                Confirmar contraseña: 
              </label>
              <input
                className="form-control"
                name="passwordConfirm"
                type="password"
                required={true}
                
                onChange={handlePasswordConfirmChange}
              />
              {
                pswConfirm && (form.password == pswConfirm ? <p className="customSuccessLabel">Las contraseñas coinciden</p> : <p className="customDangerLabel">Las contraseñas no coinciden</p>)
              }

            </FormGroup>

            <FormGroup>
              <label>
                Pocisión: 
              </label>
              <input
                className="form-control"
                name="position"
                type="text"
                required={true}
                onChange={handleChange}
              />
            </FormGroup>

            
          </ModalBody>

          <ModalFooter>

            

          <Button
              className="btn btn-danger mr-auto"
              onClick={() => closeAddModal()}
            >
              <Cancel/>
              {"  Cancelar"}
          </Button>

            <Button
            type="submit"
              color="info">
              <Add/>
              {"  Insertar"}
            </Button>
            
          </ModalFooter>
          </form>
        </Modal>




        <Modal isOpen={removeModal}>
          <ModalHeader>
          <div><h3>Eliminar contacto</h3></div>
          </ModalHeader>

          <ModalBody>
          <div><h5>{`Está seguro que desea eliminar definitivamente a ${form.name}?`}</h5></div>
          <Alert className="alert-danger mr-auto ml-auto"><strong>Advertencia: </strong> Este cambio no se puede deshacer.</Alert>
          
          </ModalBody>
          <ModalFooter>
          
          <Button
          className="mr-auto"
              color="danger"
              onClick={() => setRemoveModal(false)}
            >
              <Cancel/>
              {"  Cancelar"}
            </Button>
          <Button
              color="info"
              onClick={() => removeContact(form)}
            >
              <Delete/>
                      {"  Eliminar"}
            </Button>
            
          </ModalFooter>
        </Modal>
        
      </>
    );
  }

export default ContactCRUD;