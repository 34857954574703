import { makeStyles } from '@material-ui/core';

export default makeStyles(
    () => (

        {
            "client_title": {
                "color": "#fff",
                "textAlign": "right"
            },
            "client_title_small": {
                "fontWeight": "bold",
                "fontSize": "13px",
                "color": "#ccc"
            },
            appBar: {
                borderRadius: 15,
                margin: '30px 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            },
            heading: {
                color: 'rgba(0,183,255, 1)',
            },
            image: {
                marginLeft: '15px',
            },
            "bk_hamburger": {
                "color": "rgba(255,255,255,10) !important",
                "borderColor": "rgba(0,0,0,0.10) !important"
            },
            "navbar_dark__navbar_nav__nav_link": {
                "color": "#fff !important",
                "fontSize": "30px !important",
                "fontWeight": "600 !important"
            },
            "navbar_dark__navbar_nav__nav_link_hover": {
                "color": "#c7ff2c !important"
            },
            "bakerTillyNavbar___ul___li_nav_item_active___a": {
                "color": "#c7ff2c !important"
            },
            "container": {
                "width": "100%",
                "white-space": "nowrap"
            }
        }


    )
)