import { AUTH, CREATING_NEW_ONE } from '../constants/actionsTypes';
import * as api from '../api';

export const signin = (formData, history) => (dispatch) => {

    api.signIn(formData).then( (response) => {
        

            if (response?.message) {
                
                dispatch({ type: 'GET_ERROR_MESSAGE', data: response.message });

            } else {
                if (response?.result?.reset) {
                    
                    dispatch({ type: AUTH, data: response });
                    history.push('/auth-reset');

                } else {
                    
                    dispatch({ type: AUTH, data: response });

                    history.push('/');

                }
            }
        }
        ).catch((err) => {
            console.log(err);
        }
        )
}

export const signup = (user, history, changePsw, creatingNewOne, editingContact) => (dispatch) => {

    api.signUp(user, changePsw, creatingNewOne, editingContact)
        .then( (response) => {

            if(creatingNewOne || editingContact){
                dispatch({ type: CREATING_NEW_ONE, data: response });

            }else{
                dispatch({ type: AUTH, data: response });
            history.push('/');
            }
        }
        ).catch((err) => {
            console.log(err);
        }
        )
}