import * as api from '../api';
import {UPLOAD_FILE} from '../constants/actionsTypes';

export const uploadCaseRequestFile = (formData, fileName, fileRequest)=> async (dispatch)=>{
    api.uploadCaseRequestFile(formData, fileName).then((response)=>{
        const {data} = response;

        
        fileRequest.file = data;
        api.updateFileRequest(fileRequest);
        dispatch({type: UPLOAD_FILE, data});
    }
        
    )
}