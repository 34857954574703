import { combineReducers } from 'redux';
import client from './client';
import authReducer  from './auth';
import cases  from './cases';
import invoices  from './invoices';
import requests  from './requests';
import fileRequest  from './fileRequest';
import casePercentage  from './casePercentage';
import comment  from './comment';
import userImage  from './userImage';
import clientUpdated from './clientUpdated';
import ratingsOfUser from './ratingsOfUser'



export default combineReducers({
    client,
    authReducer,
    cases,
    invoices,
    requests,
    fileRequest,
    casePercentage,
    comment,
    userImage,
    clientUpdated,
    ratingsOfUser
});