import React, { useEffect, useState } from 'react';
import logo from '../../images/logoblack.svg';

import useStyles from "./styles";
import Input from "./Input/Input";
import { signin, signup } from "../../actions/auth";
//import { getCurrentClient } from "../../actions/client";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import './styles.css';




const initialState = { email: '', password: '', passwordConfirm: '' };

const Auth = ({ isReseting, isChangingPassword}) => {
    
    const [formData, setFormData] = useState(initialState);
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile'))?.result);
    const [passMessage, setPassMessage] = useState("");
    
    const [show, setShow] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
    
    const message = useSelector((state)=>{
        return state.authReducer.authData
    });

    const handleSubmit = (e) => {
        
        e.preventDefault();

        if(isReseting){
            if(formData.password != formData.passwordConfirm){
                setPassMessage("Las contraseñas no coinciden.");
                setShow(true);
            }else if(formData.password.length < 8){
                setPassMessage("La contraseña debe tener más de 8 caracteres.");
                setShow(true);
            }else{
                user.password = formData.password;
                user.reset = false;
                setUser(user);
                dispatch(signup(user, history, isChangingPassword, false, false));
            }
        }else{
            
            dispatch(signin(formData, history));
            setShow(true);
        }
    }

    


    //Handle when values change in the form
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        
    }

    const classes = useStyles();

    return (

        <div className={classes.bodyCenter}>
            <main className="form_signin div-to-align text-center mt-0" >
                <form onSubmit={handleSubmit}>


                    <img className="mb-4" src={logo} alt="" width="272" height="57"  />
                    {
                        (message && show) && 
                        <Alert variant="danger" onClose={()=> setShow(false)} dismissible>
                            {message}
                        </Alert>
                    }
                    {
                        isReseting ?
                            <>
                                {
                                    !isChangingPassword ? <Alert variant="info" className="alertCustom">
                                    Su contraseña es temporal, por favor introduzca una nueva.
                                    </Alert> : <Alert variant="secondary" className="alertCustom">
                                    Escriba su nueva contraseña en los campos indicados y guarde.
                                    </Alert>
                                }
                                {
                                passMessage &&
                                <Alert variant="danger" onClose={()=> {setShow(false); setPassMessage("")}} className="alertCustom" dismissible>
                                {passMessage}
                                </Alert>
                                }
                                <label htmlFor="inputEmail" className="visually-hidden"></label>
                                <Input name="password" type={showPassword ? "text" : "password"} label="nueva contraseña" handleChange={handleChange} handleShowPassword={handleShowPassword} />

                                <label htmlFor="inputEmail" className="visually-hidden"></label>


                                <Input name="passwordConfirm" type="password" label="confirmar contraseña" handleChange={handleChange} />
                            </>

                            :

                            <>
                                <label htmlFor="inputEmail" className="visually-hidden"></label>
                                <Input name="email" type="email" label="email" handleChange={handleChange} autoFocus />

                                <label htmlFor="inputEmail" className="visually-hidden"></label>


                                <Input name="password" type={showPassword ? "text" : "password"} label="contraseña" handleChange={handleChange} handleShowPassword={handleShowPassword} />
                            </>
                    }


                    <div className="checkbox m-4">

                    </div>


                    {
                        !isChangingPassword ? <button className="w-100 btn btn-lg btn-dark" type="submit">Ingresar</button> :
                        <button className="w-100 btn btn-lg btn-dark" type="submit">Guardar</button>
                    }



                    <p className="mt-5 mb-3 text-dark">&copy; Baker Tilly Dominican Republic</p>
                </form>
            </main>
        </div>


    )
}

export default Auth;