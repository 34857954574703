import {GET_CASE_PERCENTAGE} from "../constants/actionsTypes";
import * as api from "../api/index";

export const getCasePercentage = (caseId)=> async (dispatch) => {
    
    try {
        await api.getCasePercentage(caseId)
        .then((data)=>{
        
        dispatch({type: GET_CASE_PERCENTAGE, data});

        })

        
    } catch (error) {
        console.log(error.message);

    }

}