import {GET_ALL_CLIENT_INVOICES } from '../constants/actionsTypes';

export default (invoices = [], action)=>{
    switch (action.type) {
        case GET_ALL_CLIENT_INVOICES:
            const clientData = {...action};
            return {...clientData.data};
        default:
            return invoices;
    }
}