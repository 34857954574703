import { GET_CASE_PERCENTAGE} from '../constants/actionsTypes';

export default (cases = [], action)=>{
    switch (action.type) {
        case GET_CASE_PERCENTAGE:
            const casePercentage = {...action.data};
            
            return {...casePercentage.data};
        default:
            return cases;
    }
}