import {GET_ALL_CLIENT_INVOICES } from '../constants/actionsTypes'
import * as api from '../api';

// Action creators
export const getCurrentClientInvoices = ()=> async (dispatch) => {
    await api.getCurrentClientInvoices()
        .then((response)=>{
        dispatch({type: GET_ALL_CLIENT_INVOICES, data: response});

        })

}