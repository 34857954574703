import {GET_USER_CLIENT } from '../constants/actionsTypes';

export default (client = {}, action)=>{
    switch (action.type) {
        case GET_USER_CLIENT:
            const clientData = {...action};
           
            return {...clientData.data};
        default:
            return client;
    }
}