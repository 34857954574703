import { makeStyles } from '@material-ui/core';

export default makeStyles(
    () => (

        {
            bodyCenter: {
                "display": "flex",
                "color": "#fff",
                "alignItems": "center",
                "paddingTop": "40px",
                "paddingBottom": "40px",
                "backgroundColor": "#f8f9fa",
                "height": "100vh",
                "justifyContent": "center"
              },
              "center": {
                "display": "flex",
                "justifyContent": "center",
                "alignItems": "center",
                "height": "100vh"
              },
              form_signin: {
                "width": "100%",
                "maxWidth": "330px",
                "padding": "15px",
                "margin": "auto"
              },
              "form_signin__checkbox": {
                "fontWeight": "400"
              },
              "form_signin__form_control": {
                "position": "relative",
                "boxSizing": "border-box",
                "height": "auto",
                "padding": "10px",
                "fontSize": "16px"
              },
              "form_signin__form_control_focus": {
                "zIndex": "2"
              },
              "form_signin_input_type__email": {
                "marginBottom": "-1px",
                "borderBottomRightRadius": "0",
                "borderBottomLeftRadius": "0"
              },
              "form_signin_input_type__password": {
                "marginBottom": "10px",
                "borderTopLeftRadius": "0",
                "borderTopRightRadius": "0"
              },
              "bd_placeholder_img": {
                "fontSize": "1.125rem",
                "textAnchor": "middle",
                "WebkitUserSelect": "none",
                "MozUserSelect": "none",
                "userSelect": "none"
              },
              "@media (min-width: 768px)": {
                "__expression__": "(min-width: 768px)",
                "bd_placeholder_img_lg": {
                  "fontSize": "3.5rem"
                }
              },
              "btnBlack": {
                "color": "black"
              },
              "alert": {
                "width": "40%"
              }
        }


    )
)