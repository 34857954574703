import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import React from 'react';
import {Alert} from 'react-bootstrap'

const InvoiceBox = (props) => {


    


    const formatDate = (date)=>{
		if(date){
			var myDate = new Date(date.slice(0, date.length-10));
		return myDate.toLocaleDateString("es-DO", {year: 'numeric', month: 'numeric', day: 'numeric'})
		}
	}
    return (

        <Link to={`/invoices/${props.invoiceId}`} className="customLink">
            <Alert variant="light" className="callout">
                
                <p className="mb-0"> Numero de caso: <strong>{props.invoiceId}</strong></p>
                <p className="mb-0"> Fecha de emición: <strong>{formatDate(props.emitedDate)}</strong></p>
                <p className="mb-0"> Detalle: <strong>{props.concept}</strong></p>

            </Alert>
        </Link>

    )


}

export default InvoiceBox;