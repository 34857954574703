export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const GET_USER_CLIENT = 'GET_USER_CLIENT';
export const GET_USER_CLIENT_CASES = 'GET_USER_CLIENT_CASES';
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const GET_ALL_CLIENT_INVOICES = 'GET_ALL_CLIENT_INVOICES';
export const GET_ERROR_MESSAGE = 'GET_ERROR_MESSAGE';
export const GET_ALL_REQUESTS = 'GET_ALL_REQUESTS';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPDATE_FILE_REQUEST = 'UPDATE_FILE_REQUEST';
export const GET_CASE_PERCENTAGE = 'GET_CASE_PERCENTAGE';
export const POST_NEW_COMMENT = 'POST_NEW_COMMENT';
export const GET_USER_IMAGE = 'GET_USER_IMAGE';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CREATING_NEW_ONE = 'CREATING_NEW_ONE';
export const GET_ALL_CASE_RATING_BY_CONTACT = 'GET_ALL_CASE_RATING_BY_CONTACT';