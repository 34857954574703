import Settings from '@material-ui/icons/Settings';
import React from 'react';
import VpnKey from '@material-ui/icons/VpnKey';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { getCurrentClient } from '../../actions/client';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import PermIdentity from '@material-ui/icons/PermIdentity';
import { updateClient } from '../../actions/updateClient';
import './settingStyles.css'
import ContactCRUD from './ContactCRUD/ContactCRUD';
import { Alert } from 'react-bootstrap';

import List from '@material-ui/icons/List';

const SettingsView = ({ govId }) => {
    var input = React.createRef();

    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem('profile')).result;

    const [readOnly, setReadOnly] = useState(true);

    const [show, setShow] = useState(false);

    const currentClient = useSelector(state => state.client);
    const [contactList, setContactList] = useState(currentClient.contactList);

    useEffect(() => {
        dispatch(getCurrentClient());
    }, [dispatch])

    useEffect(() => {
        if (!input.current.value) {
            input.current.value = govId ? govId : ""
        }
    }, [input])

    const [wasOnClick, setWasOnClick] = useState(false);

    useEffect(() => {
        if (!contactList) {
            setContactList(currentClient.contactList);
        }

    }, [currentClient])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (input.current.value.length < 20) {
            dispatch(updateClient(user.leadId, input.current.value))
            setReadOnly(true);
            setShow(false);
        } else {
            setShow(true);
        }

    }

    const handleClick = (e) => {
        setReadOnly(false);
    }

    return (
        <main>
            <div className="album py-5 bg-light">
                <div className="container">
                    <div className="row mt-5 mb-4">
                        <div className="col">
                            <h4> <Settings /> Ajustes</h4>
                        </div>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Ajustes</li>
                        </ol>
                    </nav>

                    <div className="row-3 mt-4">
                        <form onSubmit={handleSubmit} className="col-12">

                            <div className="row">
                                <PermIdentity />
                                <h5 >RNC, NIT o Cédula</h5>
                            </div>

                            <Alert variant="danger row col-4" onClose={() => setShow(false)} show={show} dismissible>Debe ser máximo 20 caracteres</Alert>
                            <div className="row">

                                <input className="form-control col-5" type="text" ref={input} readOnly={readOnly} />



                            </div>

                            {!readOnly && <button type="submit" className="btn btn-info customButtonPositionSave" aria-pressed="false"><><Save /> Guardar</></button>}
                        </form>
                        <div className="row">
                        {readOnly && <button type="button" className="btn btn-secondary customButtonPositionEdit" aria-pressed="false" onClick={handleClick}><><Edit /> Editar</></button>}
                        </div>
                    </div>

                    <div className="row ml-2">
                        <a className="mt-5" href="/auth-reset-changePassword"> <h5> <VpnKey /> Cambiar contraseña</h5> </a>
                    </div>

                    <div className="row mt-5 mb-4">
                        <div className="col">
                            <h4> <List /> Lista de Contactos:</h4>
                        </div>
                    </div>


                    {
                        contactList ? <ContactCRUD dataToGrid={contactList} />
                            : "Cargando..."
                    }



                </div>
            </div>
        </main>
    )
}

export default SettingsView;