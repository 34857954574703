import {UPLOAD_FILE } from '../constants/actionsTypes';

export default (fileRequest = {}, action)=>{
    switch (action.type) {
        case UPLOAD_FILE:
            const fileUploadResponse = {...action};
           
            return { ... fileRequest,  ...fileUploadResponse};;
        default:
            return fileRequest;
    }
}