import {GET_ALL_CASE_RATING_BY_CONTACT } from '../constants/actionsTypes';

export default (ratings = [], action)=>{
    switch (action.type) {
        case GET_ALL_CASE_RATING_BY_CONTACT:




            const clientData = action.data;


            return clientData;
        default:
            return ratings;
    }
}