import React from 'react';
import './styles.css'

const InvoiceGrid = (props)=>{
    const formatMoney = (amount) => {
		return (amount).toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD'
		})
	}

	const formatDate = (date)=>{
		if(date){
			var myDate = new Date(date.slice(0, date.length-10));
		return myDate.toLocaleDateString("es-DO", {year: 'numeric', month: 'numeric', day: 'numeric'})
		}
	}



    return (
        <>
        
        <table className="table table-striped">
									<thead>
										<tr>
											<th scope="col">Factura #</th>
											<th scope="col">Cuota no.</th>
											<th scope="col">Cuota %</th>
											<th scope="col">Monto neto</th>
											<th scope="col">ITBIS</th>
											<th scope="col">Total</th>
											<th scope="col">Moneda</th>
											<th scope="col">Fecha emitida</th>
											<th scope="col">Factura pagada</th>
											<th scope="col">Concepto</th>
											<th scope="col">Estado</th>
										</tr>
									</thead>
									<tbody>

										{


                                        
                                        Object.keys(props.invoices).map((element, index)=>{
                                            var status = "";

                                            props.invoices[index].status == 3 ? status = "Emitida" : status = "Pagada";

                                            return <tr key={props.invoices[index].id}>
												<th scope="row">{props.invoices[index].billNo}</th>
											<th scope="row">{props.invoices[index].index}</th>
											<td>{Math.round((props.invoices[index].amount/props.currentCase.billingInfo.honoraries)*100) + "%"}</td>
											<td><a href={`/invoices/${props.invoices[index].id}`}><span className="badge bg-dark clickableLabel">{formatMoney(props.invoices[index].amount)}</span></a></td>
											<td>{formatMoney(props.invoices[index].taxes)}</td>
											<td>{formatMoney(props.invoices[index].amountPlusTaxes)}</td>
											<td>{props.currentCase.billingInfo.currency.currency}</td>
											<td>{formatDate(props.invoices[index].emitedDate)}</td>
											<td>{formatDate(props.invoices[index].payStamp?.date)}</td>
											<td>{props.invoices[index].comment}</td>
											<td><span className={`badge bg-${status == "Emitida" ? "warning" : "success clickableLabel"}`}>{status}</span></td>

										</tr>
                                        })

                                        }
									</tbody>
								</table>
        </>
    )
}

export default InvoiceGrid;