import {POST_NEW_COMMENT } from '../constants/actionsTypes';

export default (comment = [], action)=>{
    switch (action.type) {
        case POST_NEW_COMMENT:
            const commentPostResponse = {...action};
           
            return {...commentPostResponse};;
        default:
            return comment;
    }
}