import axios from 'axios';
import jwt from 'jsonwebtoken';
import { UNAUTHENTICATED, INVALID_USER, INACTIVE_USER, INVALID_PASSWORD } from '../constants/alertMessages';
import shajs from 'sha.js';


const getLoggedInUserData = () => {
    let token = JSON.parse(localStorage.getItem('profile'))?.token;
    if (token) {
        var decodedData = jwt.verify(token, 'test');
        return decodedData;
    }
}


//Client data is being retrived right here
export const getCurrentClient = () => {
    try {
        var loggedInUser = getLoggedInUserData();

        return axios.get(
            "https://btdrdev.appiancloud.com/suite/webapi/DQZOng",
            {
                params: {
                    clientid: loggedInUser?.id
                },
                headers: { 'Appian-API-Key': process.env.REACT_APP_API_KEY }
            }
        ).then((response) => {
            return response.data
        })
            .catch((err) => console.log(err));

    } catch (error) {
        console.log(error.message);
    }
}





export const getCurrentClientCases = () => {
    var loggedInUser = getLoggedInUserData();

    const clientId = loggedInUser?.id;

    if (!clientId) return { message: UNAUTHENTICATED };
    return axios.get(
        "https://btdrdev.appiancloud.com/suite/webapi/g7Dwkw",
        {
            params: {
                clientId: clientId
            },
            headers: { 'Appian-API-Key': process.env.REACT_APP_API_KEY }
        }
    ).then((response) => {
        return response.data
    })
        .catch((err) => console.log(err.message));
}

//Useless for now
export const getUserImage = (imageId) => axios.get(
    `https://btdrdev.appiancloud.com/suite/webapi/Jn0aAw/${imageId}`,
    {
        headers: { 'Appian-API-Key': process.env.REACT_APP_API_KEY },
        responseType: "blob"
    }
).then((response) => {
    const url = URL.createObjectURL(response.data);
    return url;
}).catch((err) => err.message)




export const getCurrentClientInvoices = () => {
    var loggedInUser = getLoggedInUserData();

    const clientId = loggedInUser?.id;

    if (!clientId) return { message: UNAUTHENTICATED };

    return axios.get(
        "https://btdrdev.appiancloud.com/suite/webapi/TqL1Vg",
        {
            params: {
                clientId: clientId
            },
            headers: { 'Appian-API-Key': process.env.REACT_APP_API_KEY }
        }
    ).then((response) => {
        return response.data
    })
        .catch((err) => console.log(err.message));

}




export const getCurrentClientRequests = () => {
    var loggedInUser = getLoggedInUserData();

    if (!loggedInUser) return { message: UNAUTHENTICATED };

    const clientId = loggedInUser?.id;
    return axios.get(
        "https://btdrdev.appiancloud.com/suite/webapi/pwa2ew",
        {
            params: {
                clientId: clientId
            },
            headers: { 'Appian-API-Key': process.env.REACT_APP_API_KEY }
        }
    ).then((response) => {
        return response.data
    })
        .then((data) => {
            return data;
        })
        .catch((err) => console.log(err.message));
}



export const uploadCaseRequestFile = (FormData, fileName) => {
    var loggedInUser = getLoggedInUserData();

    if (!loggedInUser) return { message: UNAUTHENTICATED };

    return axios.post(
        'https://btdrdev.appiancloud.com/suite/webapi/ErOQ6w',
        FormData,
        {
            "headers": {
                "Access-Control-Allow-Origin": "*",
                'Appian-API-Key': process.env.REACT_APP_API_KEY,
                'Appian-Document-Name': fileName
            },
        }
    )

}



export const updateFileRequest = (fileRequest) => {
    var loggedInUser = getLoggedInUserData();

    if (!loggedInUser) return { message: UNAUTHENTICATED };
    
    return axios.post(
        "https://btdrdev.appiancloud.com/suite/webapi/X4drcQ",
        fileRequest,
        {
            "headers": {
                "Access-Control-Allow-Origin": "*",
                'Appian-API-Key': process.env.REACT_APP_API_KEY
            },
            'params': {
                'requestId': fileRequest.id,
                'fileId': fileRequest.file
            }
        }
    )
}

export const addAComment = (FormData) => {
    var loggedInUser = getLoggedInUserData();

    if (!loggedInUser) return { message: UNAUTHENTICATED };

    return axios.post(
        'https://btdrdev.appiancloud.com/suite/webapi/gzXh3Q',
        FormData,
        {
            "headers": {
                "Access-Control-Allow-Origin": "*",
                'Appian-API-Key': process.env.REACT_APP_API_KEY
            },
        }
    )
}

// Update govId from current client

export const updateGovId = (leadId, rnc) => {
    var loggedInUser = getLoggedInUserData();

    if (!loggedInUser) return { message: UNAUTHENTICATED };

    return axios.post(
        "https://btdrdev.appiancloud.com/suite/webapi/lFCIWw",
        {},
        {
            headers: {
                'Appian-API-Key': process.env.REACT_APP_API_KEY
            },
            params: {
                leadId: leadId,
                rnc: rnc
            }
        }
    )
}


export const getCasePercentage = (caseId) => axios.get(
    "https://btdrdev.appiancloud.com/suite/webapi/k_CL3w",
    {
        params: {
            caseId: caseId
        },
        headers: { 'Appian-API-Key': process.env.REACT_APP_API_KEY }
    }
)



//Sign In and Sign Up apis are being used here
export const signIn = (FormData) => {
    const { email, password } = FormData;

    return axios.get(
        //BBO_GetClientUserContactByEmail
        "https://btdrdev.appiancloud.com/suite/webapi/Ha8aMg",
        {
            "params": {
                "email": email
            },
            "headers": { 'Appian-API-Key': process.env.REACT_APP_API_KEY }
        }
    ).then((response) => response.data)
        .then(async (data) => {

            if (data.length == 0) return { message: INVALID_USER };


            const user = data[0];
            if (!user.isActive) return { message: INACTIVE_USER };

            const hashedPassword = shajs('sha256').update(password).digest('hex');

            const isPasswordCorrect = (hashedPassword == user.password);



            if (!isPasswordCorrect) return { message: INVALID_PASSWORD };

            const token = jwt.sign({ email: user.email, id: user.leadId }, 'test', { expiresIn: "1h" });


            return { result: user, token };
        })
        .catch((err) => {
            return { message: err.message }
        });
}




export const signUp = (user, changePsw, creatingNewOne, editingContact) => {
    const { id, name, phoneNumber, email, position, department, educationLevel, ext, password, leadId, isActive, reset } = user;

    try {
        return axios.get(
            //BBO_GetClientUserContactByEmail
            "https://btdrdev.appiancloud.com/suite/webapi/Ha8aMg",
            {
                "params": {
                    "email": email
                },
                "headers": { 'Appian-API-Key': process.env.REACT_APP_API_KEY }
            }
        ).then((response) => response.data)
            .then(async (data) => {


                if (!data[0]?.reset && !changePsw && !editingContact) {
                    if (data.length != 0) return { message: "El usuario ya existe." };
                }


                const hashedPassword = shajs('sha256').update(password).digest('hex');

                const params = {
                    id,
                    name,
                    phoneNumber,
                    email,
                    email,
                    position,
                    department,
                    educationLevel,
                    ext,
                    password: editingContact ? user.password : hashedPassword,
                    leadId,
                    isActive,
                    reset
                }

                if(creatingNewOne){
                    return axios.post(
                        //BBO_CreateNewUserContactForClient
                        "https://btdrdev.appiancloud.com/suite/webapi/ylw4dg",
                        {},
                        {
                            "headers": {
                                'Appian-API-Key': process.env.REACT_APP_API_KEY
                            },
                            params: params
                        }
    
                    ).then(response => 
                        {
                            return response.data
                        });
                }else{
                    return axios.post(
                        //BBO_EditCreatedUserContactForClient
                        "https://btdrdev.appiancloud.com/suite/webapi/kfi2lA",
                        {},
                        {
                            "headers": {
                                'Appian-API-Key': process.env.REACT_APP_API_KEY
                            },
                            params: params
                        }
    
                    ).then((response) => response.data)
                        .then((data) => {
                            if(editingContact){
                                return data
                            }else{
                                const result = data[0];
                                const token = jwt.sign({ email: result.email, id: result.leadId }, 'test', { expiresIn: "1h" });
    
                                return { result, token }
                            }
                        })
                        .catch((err) => err.message);
                }

            })
            .catch((err) => console.log(err));


    } catch (error) {
        return { message: error.message }
    }
}

export const removeContactService = (contactId)=>{
    var loggedInUser = getLoggedInUserData();

    if (!loggedInUser) return { message: UNAUTHENTICATED };
    return axios.delete(
        "https://btdrdev.appiancloud.com/suite/webapi/8KHJYQ",
        {
            "headers": {
                "Appian-Api-Key": process.env.REACT_APP_API_KEY
            },
            params: {
                "id": contactId
            }
        }
    )
}

export const rateCloseCase = (contactId, caseId, surveyType, formData)=>{

    var loggedInUser = getLoggedInUserData();

    if (!loggedInUser) return { message: UNAUTHENTICATED };

    let averageRate = null;

    if(surveyType == 1){
        averageRate = ((formData.customerService + formData.quality + formData.assignedTeam + formData.deliverable + formData.recommendationChance) / 5)
    }

    return axios.post(
        "https://btdrdev.appiancloud.com/suite/webapi/IhX3Fg",
        {
            "contactId": contactId,
            "caseId": caseId,
            "surveyType": surveyType,
            "averageRate": averageRate,
            "surveyData": formData
        },
        {
            "headers": {
                "Appian-Api-Key": process.env.REACT_APP_API_KEY
            }
        }
    )
}


export const getRatingsByContact = (contactId)=>{
    var loggedInUser = getLoggedInUserData();

    if (!loggedInUser) return { message: UNAUTHENTICATED };
    
    return axios.get(
        "https://btdrdev.appiancloud.com/suite/webapi/Vusj6g",
        {
            "headers": {
                "Appian-Api-Key": process.env.REACT_APP_API_KEY
            },
            "params": {
                contactId
            }
        }
    )
}
