import React from 'react'

const Footer = () => {

    return (
        <>
            <footer className="text-muted py-5">
                <div className="container">
                    <p className="float-end mb-1">
                    </p>
                    <p className="mb-1">&copy; Baker TIlly Dominican Republic</p>
                </div>
            </footer>
            <script src="../js/bootstrap.bundle.min.js"></script>
        </>
    )

}

export default Footer;