import React, { useState} from 'react';
import './styles.css';
import {useDispatch} from 'react-redux';
import axios from 'axios';

const UserBox = (props)=>{

	const dispatch = useDispatch();

	const [imageUrl, setImageUrl] = useState("");
	

	
	axios.get(
		`https://btdrdev.appiancloud.com/suite/webapi/Jn0aAw/${props.currentUser.imageId}`,
		{
		  headers: { 'Appian-API-Key': process.env.REACT_APP_API_KEY},
		  responseType: "blob"
		}
	).then((response)=> {
		const url = URL.createObjectURL(response.data);
		setImageUrl(url);
		return url;
	}).catch((err)=>err.message)
	

	

    return (
        <>
        <a href={`mailto: ${props.currentUser.user}`} className="list-group-item list-group-item-action" aria-current="true">
				  <div className="row mt-2 mb-2">
                  <img className="customUserImage" alt="User image" src={imageUrl}/>
					<div className="col-10 text-left lh-1">					
				 		 <h5 className="mb-1">{props.currentUser.fullName}</h5>
						 <small>{props.currentUser.position.positionName}</small><br/>
				  		 <small>{props.currentUser.user}</small>				
					</div>
				  </div>				
			  </a>
        
        </>
    )
}

export default UserBox;