import React, { useEffect, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import NavbarComponent from './components/Navbar/NavbarComponent';
import Home from './components/Home/Home';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Auth from "./components/Auth/Auth";
import Footer from './components/Footer/Footer';
import CaseView from './components/CaseView/CaseView';
import Cases from './components/Cases/Cases';
import { useSelector, useDispatch} from 'react-redux';
import InvoiceView from './components/InvoiceView/InvoiceView';
import SettingsView from './components/SettingsView/SettingsView';
import { getCurrentClient } from './actions/client';


const App = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

    const auth = useSelector((state) => {
        return state.authReducer.result
    })

    useEffect(() => {
        dispatch(getCurrentClient());
    }, [])

    const clientRNC = useSelector((state) => {
        return state.client?.govId
    })

    localStorage.setItem("rnc", clientRNC);



    return (

        <>
        
        <BrowserRouter>

            <Route path="/auth" >

                <>
                    <Auth isReseting={false} isChangingPassword={false} />
                </>
            </Route>
            <Route path="/auth-reset" >

                <Auth isReseting={true} isChangingPassword={false} />

            </Route>

            <Route path="/auth-reset-changePassword" >

                <Auth isReseting={true} isChangingPassword={true} />

            </Route>

            
            <Route exact path="/">
                {auth || user ? <>
                    <NavbarComponent />
                    <Home />
                </> : <Redirect to="/auth" />}
            </Route>

            <Route exact path="/cases/:id">
                {auth || user ? <>
                    <NavbarComponent />
                    <CaseView />
                </> : <Redirect to="/auth" />}
            </Route>

            <Route path="/invoices/:id">
                {auth || user ? <>
                    <NavbarComponent />
                    <InvoiceView />
                </> : <Redirect to="/auth" />}
            </Route>
            

            <Route exact path="/cases">
                {auth || user ? <>
                    <NavbarComponent />
                    <Cases />
                </> : <Redirect to="/auth" />}
            </Route>

            <Route exact path="/cases/:id/rating">
                {auth || user ? <>
                    <NavbarComponent />
                    <CaseView isRating={true}/>
                </> : <Redirect to="/auth" />}
            </Route>

            <Route exact path="/settings">
                {auth || user ? <>
                    <NavbarComponent />
                    <SettingsView govId={clientRNC}/>
                </> : <Redirect to="/auth" />}
            </Route>
            <Footer />
        </BrowserRouter>
        </>
        
    )
}

export default App;