import React, {useEffect, useState} from "react";

import { CircularProgress, Container, Grow } from '@material-ui/core';
import Description from '@material-ui/icons/Description';
import Work from '@material-ui/icons/Work';
import './styles.css';
import CaseBox from "./CaseBox/caseBox";
import InvoiceBox from "./InvoiceBox/InvoiceBox";
import {getCurrentClientCases} from "../../actions/cases";
import {getCurrentClientInvoices} from "../../actions/invoices";
import {getAllClientRequests} from "../../actions/requests";
import {useSelector, useDispatch} from 'react-redux';
import PendingRequestBox from './PendingRequestBox/PendingRequestBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {useHistory} from 'react-router-dom';
import decode from 'jwt-decode';
import { getAllRatingsByContact } from '../../actions/caseRatings';


const Home = () => {
    const dispatch = useDispatch(); 
    const [show, setShow] = useState(true);
    const [interval, setInterval] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const history = useHistory();

    const logout = () =>{
        dispatch({type: 'LOGOUT'});
    
        setUser(null);
        history.push('/auth');
        
      }
    
    useEffect(() => {
    
        const token = user?.token;
    
        if (token) {
          const decodedToken = decode(token);
    
          if (decodedToken.exp * 1000 < new Date().getTime()){
            logout();
          }else{
            dispatch(getCurrentClientCases());
            dispatch(getCurrentClientInvoices());
            dispatch(getAllClientRequests());
            dispatch(getAllRatingsByContact(user.result.id));
            componentDidMount();
            componentWillUnmount();
          }
          
          }
        
    
        setUser(JSON.parse(localStorage.getItem('profile')));
      }, []);


    const getCaseById = (caseId)=>{
        const caseToReturn = Object.values(cases).filter((element, index)=>{
            if(cases[index].id == caseId) 
            {	
                return cases[index];
            }
        })
        return caseToReturn;
    }


    const cases = useSelector((state)=> {
        return state.cases;
    });

    const openCases = Object.values(cases).filter((value, index)=>{
        if(cases[index].status != 4){
            return cases[index];
        }
    })


    const invoices = useSelector((state)=> {
        return state.invoices;
    });

    const openInvoices = Object.values(invoices).filter((value, index)=>{
        if(invoices[index].status == 3){
            return invoices[index];
        }
    })


    const requests = useSelector((state)=> {
        return state.requests;
    });

    const requestsWithFiles = Object.keys(requests).filter((value, index)=>{
        if(!requests[index].file){
            return true
        }else{
            return false
        }
    })

    const ratingsOfCurrentUser = useSelector(state => state.ratingsOfUser);

    const componentDidMount = ()=> {
        setInterval(
            setTimeout(function () {
               setShow(false);
            }, 5000)
        );
      } 
    
      const componentWillUnmount = ()=> {
        setInterval(clearTimeout(interval));
      }

      const pendingToRate = Object.values(cases).filter((value,index) =>{


        if(cases[index].status == 4 && cases[index].caseSurveyInfo?.surveyType > 0 && cases[index].caseSurveyInfo.sendSurvey){
            return cases[index]
        }
    })


    return (
        <div className="customContainer">
        <Container maxWidth="lg">
            <Grow in>
                <Container>

                        
                    
                        <div className="album py-5 bg-light">
                            <div className="container">
                                <div className="row mb-4">
                                    <div className="col">
                                        
                                        <h4>
                                        <Work className="mr-1"/>
                                                Casos abiertos</h4>
                                    </div>
                                </div>


                                {
                                    Object.keys(openCases).length >0 ?
                                    
                                    Object.keys(openCases).map((value,index) =>{
                                        return <CaseBox 
                                        key={openCases[index].id} 
                                        currentCase={openCases[index]} 
                                        ratingsOfCurrentUser={ratingsOfCurrentUser}></CaseBox>
                                    }) :
                                    show ? <CircularProgress /> : <p>No hay casos abiertos disponibles</p>
                                }

                                <div className="row mt-5 mb-4">
                                
                                    <div className="col">
                                    
                                        <h4> <Description/>  Facturas abiertas</h4>
                                    </div>
                                </div>

                                {
                                    
                                    Object.keys(openInvoices).length >0 ?
                                    
                                    Object.keys(openInvoices).map((value,index) =>{
                                        
                                            return <InvoiceBox key={openInvoices[index].id} 
                                            invoiceId={openInvoices[index].id} 
                                            emitedDate={openInvoices[index].emitedDate} 
                                            concept={openInvoices[index].comment}></InvoiceBox>
                                        
                                    }) :
                                    show ? <CircularProgress /> : <p>No hay facturas abiertas disponibles.</p>
                                }




                                
                                <div className="row mt-5 mb-4">
                                    <div className="col">
                                        <h4> <AssignmentIcon/> Entregables pendientes</h4>
                                    </div>
                                </div>
                                
                                {
                                    requestsWithFiles.length > 0  ?
                                    Object.keys(requests).map((value, index)=>{
                                        return !requests[index].file && <PendingRequestBox
                                        key={requests[index].id}
                                        case={getCaseById(requests[index].caseId)[0]}
                                        request={requests[index]}
                                        >

                                        </PendingRequestBox>
                                    }) : show ? <CircularProgress /> : <p>No hay entregables pendientes.</p>
                                }

                                <div className="row mt-4 mb-4">
                                    <div className="col">
                                        
                                        <h4>
                                        <Work className="mr-1"/>
                                                Casos pendientes de calificar</h4>
                                    </div>
                                </div>


                                {
                                    pendingToRate.length > 0 ?
                                    
                                    Object.keys(pendingToRate).map((value,index) =>{

                                        return <CaseBox 
                                        key={pendingToRate[index]?.id} 
                                        currentCase={pendingToRate[index]}
                                        ratingsOfCurrentUser={ratingsOfCurrentUser}></CaseBox>
                                    }) :
                                    show ? <CircularProgress /> : <p>No hay casos pendientes por calificar disponibles.</p>
                                }

                            </div>
                        </div>
                        <div />
                    


                </Container>
            </Grow>
        </Container>
        </div>
    )
}


export default Home;