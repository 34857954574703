import * as api from '../api';
import {GET_ALL_REQUESTS} from '../constants/actionsTypes';

export const getAllClientRequests = ()=> async (dispatch)=>{
    api.getCurrentClientRequests().then((response)=>{
        
        dispatch({type: GET_ALL_REQUESTS, response});
    }
        
    )
}