import * as api from '../api';
import {POST_NEW_COMMENT} from '../constants/actionsTypes';

export const postNewComment = (formData)=> async (dispatch)=>{
    api.addAComment(formData).then((response)=>{
        const {data} = response;
        const result = data[0].caseComment;

        dispatch({type: POST_NEW_COMMENT, result});
    }
        
    )
}