import {GET_USER_CLIENT, GET_USER_CLIENT_CASES} from '../constants/actionsTypes';

export default (cases = [], action)=>{
    switch (action.type) {
        case GET_USER_CLIENT_CASES:
            const clientCasesData = {...action.data};
            
            return {...clientCasesData};
        default:
            return cases;
    }
}