
import React, {useState, useEffect} from 'react';
import "./styles.css";
import {uploadCaseRequestFile} from '../../../actions/sendFileRequested';
import {useDispatch, useSelector} from 'react-redux';
import Check from '@material-ui/icons/Check';

const PendingRequestBox = (props) => {
    const [file, setFile] = useState({});
    const [selectedRequest, setSelectedRequest] = useState({});

    const dispatch = useDispatch();

    const request = useSelector((state)=>{
        return state.fileRequest.data
    });

    const handleFileInputChange = (e)=>{

        setFile(e.target.files[0]);
    }


    const handleSubmit = (e)=>{
        e.preventDefault();
        setSelectedRequest(props.request);

        dispatch(uploadCaseRequestFile(file, file.name, props.request));
    }

    return (
        <>
            {
                request && props?.request.id == selectedRequest?.id ? 
                <div className="calloutRequestsSent container">
                <p className="no-hover mb-0">Caso: <strong>{props.case?.id}</strong></p>
                <p className="mb-0">Nombre del caso: <strong>{props.case?.name}</strong></p>
                <p className="mb-0">Pendiente: <strong>{props.request?.title}</strong></p>
                <p className="mb-0">Descripción: <strong>{props.request?.description}</strong></p><br />
                <p className="col-2 align-content-end advice">Entregado  <Check/> </p>
                

            </div> :


            <div className="calloutRequests container">
            <p className="no-hover mb-0">Caso: <strong>{props.case?.id}</strong></p>
            <p className="mb-0">Nombre del caso: <strong>{props.case?.name}</strong></p>
            <p className="mb-0">Pendiente: <strong>{props.request?.title}</strong></p>
            <p className="mb-0">Descripción: <strong>{props.request?.description}</strong></p><br />
            

            <form onSubmit={handleSubmit} encType="multipart/form-data">
            
            <div className="input-group">
            <div className="custom-file">
                <input
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFile01"
                    onChange={handleFileInputChange}
                    required={true}
                />
                <label className="custom-file-label inputFileRequest" htmlFor="inputGroupFile01">
                    {file.name}
                </label>
                <button className="w-25 btn btn-lg btn-info" type="submit" > <Check/><span> Enviar</span></button>
            </div>
            
            </div>
            
            </form>
            

            

        </div>
            }
        </>
    )
}


export default PendingRequestBox;
