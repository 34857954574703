import React, {useEffect, useState} from "react";

import { CircularProgress} from '@material-ui/core';
import Work from '@material-ui/icons/Work';
import CaseBox from "../Home/CaseBox/caseBox";
import {getCurrentClientCases} from "../../actions/cases";
import {useSelector, useDispatch} from 'react-redux';
import { getAllRatingsByContact } from '../../actions/caseRatings';


const Cases = () => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    const [interval, setInterval] = useState(0);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).result);


    useEffect(() => {
        dispatch(getCurrentClientCases());
        dispatch(getAllRatingsByContact(user.id));
        componentDidMount();
        componentWillUnmount();
    }, []);

    const ratingsOfCurrentUser = useSelector(state => state.ratingsOfUser);

    const cases = useSelector((state) => {
        return state.cases;
    });


    const componentDidMount = () => {
        setInterval(
            setTimeout(function () {
                setShow(false)
            }, 5000)
        );
    }

    const componentWillUnmount = () => {
        setInterval(clearTimeout(interval));
    }

    return (
        <>
            <div className="album py-5 bg-light">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col">

                            <h4>
                                <Work className="mr-1" />
                                                Todos los casos</h4>
                        </div>
                    </div>


                    {
                        Object.keys(cases).length > 0 ?

                            Object.keys(cases).map((value, index) => {
                                return <CaseBox key={cases[index].id}
                                currentCase={cases[index]}
                                ratingsOfCurrentUser={ratingsOfCurrentUser}
                                ></CaseBox>
                            }) :
                            show ? <CircularProgress /> : <p>No hay casos disponibles</p>
                    }
                </div>
            </div>
        </>
    )
}

export default Cases;