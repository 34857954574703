import React, {useState, useEffect} from "react";
import { Alert } from 'react-bootstrap'
import './style.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";


import Star from '@material-ui/icons/Star'


const CaseBox = ( { currentCase, ratingsOfCurrentUser } ) => {



    const currentCaseAndContactRate = Object.values(ratingsOfCurrentUser).filter((item, index) => {
        if (ratingsOfCurrentUser[index].case == currentCase.id) {
            return ratingsOfCurrentUser[index].surveyData;
        }
    });

    return (

           <div className="container">
           <div className="row py-md-0">
           <Link to={`/cases/${currentCase.id}`} className="col-10 customLink m-0" >
           <Alert variant="light" className={currentCase.status != 4 ? "callout-caseOpen" : "callout"}>
                <h4>{currentCase.name}</h4>
                <p className="mb-0"> Numero de Caso: <strong>{currentCase.id}</strong></p>
                </Alert>
            </Link>
           
            {currentCaseAndContactRate.length < 2 && currentCase.caseSurveyInfo?.surveyType != 0 && currentCase.caseSurveyInfo?.surveyType != null && currentCase.caseSurveyInfo?.sendSurvey && <div className="col-2 my-auto">
                <a href={`/cases/${currentCase.id}/rating`}>
                <button className="btn btn-lg btn-info py-lg-4">
                    <div>
                    <Star className="mb-1"/>
                    <span className="mt-5">{"  Calificar"}</span>
                    </div>
                </button>
                </a>
            </div>}
           </div>
           </div>





    )
}

export default CaseBox;