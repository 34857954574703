import {GET_USER_IMAGE} from '../constants/actionsTypes';



export default (useImage = 0, action)=>{
    switch (action.type) {
        case GET_USER_IMAGE:

             return action.response;
            default: 
            return useImage;
    }
}